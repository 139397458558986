/* .App {
  text-align: center;
}
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */
.dark-green {
  color: #08ab93 !important;
}
.light-green {
  color: #08ab931a !important;
}
.bg-dark-green {
  background-color: #08ab93 !important;
}
.bg-light-green {
  background-color: #08ab931a !important;
}
.bg-light-green1 {
  background-color: #08ab931a !important;
}
.text-gray {
  color: #05262166;
}
.button-hovers:hover {
  cursor: pointer;
  background-color: #ffffff !important;
  color: #4233b0 !important;
}

/* .secondary-button-hover:hover{
  cursor: pointer;
  background-color: #4233b0 !important;
  color: #ffffff !important;
} */

.button-hover {
  font-weight: 500 !important;
}

.error-space {
  height: 10px !important;
}

.error-txt {
  color: #e10000;
  font-size: 12px;
  font-weight: 400;
}

.error-icon {
  position: relative;
  top: 3px;
  margin: 0px 2px 0px 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.feature-quill-height .ql-container.ql-snow {
  height: 150px !important;
}

.quill-error .ql-container.ql-snow {
  border-left: 1px solid red !important;
  border-right: 1px solid red !important;
  border-bottom: 1px solid red !important;
}

.quill-error .ql-toolbar.ql-snow {
  border-left: 1px solid red !important;
  border-right: 1px solid red !important;
  border-top: 1px solid red !important;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
  padding: 5px 5px 5px 45px !important;
}

.react-tel-input .form-control:disabled {
  background-color: #f8f8f8 !important;
}

a {
  color: black !important;
  text-decoration: none !important;
}

.input-disabled {
  background-color: #f8f8f8 !important;
  color: #6c757d !important;
  cursor: not-allowed;
}

.formInput input:focus {
  border: 1px solid #cccccc !important;
  background-color: #f8f8f8 !important;
  color: #6c757d !important;
  cursor: not-allowed;
}

.roles-select-label {
  font-size: 16px !important;
  font-weight: 600;
  position: relative;
  top: 7px;
}

.search-input {
  margin: 0px !important;
}

.input-symbol {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: grey !important;
  font-weight: 600;
}

.input-symbol-left {
  position: absolute;
  left: 7px;
  top: 8px;
  font-size: 16px;
  color: grey !important;
  font-weight: 600;
}

.padding-search {
  padding-right: 22px !important;
}

.border-lines {
  border-left: 1px solid #cdcece;
}

.width-mod-1 {
  width: 5% !important;
}

.width-mod-2 {
  width: 47% !important;
}

.react-tel-input .error-input {
  border: 1px solid red !important;
}

.pending-spin {
  display: inline;
  width: 1rem;
  height: 1rem;
  /* margin-right: 0.5rem; */
  color: #edf2f7;
  animation: spin 1s linear infinite;
  color: #adb1b6;
  fill: #ff782c;
}

.pending-progress-spin {
  display: inline;
  width: 3.3rem;
  height: 3.3rem;
  /* margin-right: 0.5rem; */
  color: #edf2f7;
  animation: progressSpin 2s linear infinite;
  color: #adb1b6;
  fill: #4233b0;
}

.pending-spin-loader {
  display: inline;
  width: 3.3rem;
  height: 3.3rem;
  /* margin-right: 0.5rem; */
  color: #edf2f7;
  animation: loaderSpin 1s linear infinite;
  color: #adb1b6;
  fill: #4233b0;
}

.retry-loader {
  animation: loaderSpin 1s linear infinite;
}

.progressIcon {
  width: 3.7rem;
  height: 3.7rem;
}

.padding-left {
  padding-left: 20px !important;
}

.p-tag {
  margin-bottom: 0.25rem !important;
}

.variant-small {
  text-transform: lowercase;
}

.variant-small::placeholder {
  /* color: #999; */
  text-transform: none;
  /* opacity: 1; Firefox uses a lower default opacity */
}

.loader-height {
  height: 50vh !important;
}

.content-container {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  /* padding-bottom: 67px; */
}

.container-bottom {
  padding-bottom: 67px;
}

.action-icon {
  background-color: #e4e4e4;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  padding: 3px 5px 5px 5px;
}

.action-icon:hover {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  padding: 3px 5px 5px 5px;
}

.sticky-actions {
  position: sticky;
  top: 59px !important;
  background-color: white;
  z-index: 10;
  padding-bottom: 20px;
  border-radius: 10px;
}

.sticky-actions-border {
  border: 1px solid #e4e4e4;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.profile-menu-items:hover {
  font-weight: 600;
  color: #4233b0 !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fff !important;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #08ab931a; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #08ab93; 
  border-radius: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes progressSpin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes loaderSpin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1280px) {
  .width-mod-2 {
    width: 100% !important;
  }
  .width-mod-1 {
    display: none !important;
    width: 0% !important;
  }
}

.calendar-container {
  max-width: 900px;
  margin: 0 auto;
}

.fc .fc-toolbar-title {
  font-size: 1.5em;
  font-weight: bold;
}

.fc-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px;
  border-radius: 5px;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-daygrid-event-title {
  font-size: 1em;
  font-weight: bold;
}

.fc-daygrid-event-time {
  font-size: 0.85em;
  font-weight: normal;
}
/* In your App.css or similar file */
.rbc-toolbar {
  background-color: #f7f7f7;
}

.rbc-event {
  background-color: #7fa2ff;
  border: none;
  color: white;
}

.rbc-event:focus {
  outline: none;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #e6e6e6;
}

.rbc-time-view {
  border: 1px solid #e6e6e6;
}

.detail-txt {
  font-size: 14px;
  color: rgb(40, 40, 40);
  font-weight: 500;
}

.dashboard-card-title {
  font-size: 16px;
}

.row {
  --bs-gutter-x: 0rem !important;
}

.input-label {
  font-size: 14px;
  font-weight: 500;
  color: rgb(40, 40, 40);
  margin-bottom: 3px;

}

iframe .ytp-cued-thumbnail-overlay-image  {
  background: #f0f0f0 !important;
  opacity: 0.51!important;
}

.ytp-cued-thumbnail-overlay {
  background: #f0f0f0 !important;
}
.reroute {
  color: #4233b0 !important;
  cursor: pointer;
  font-weight: 500 !important;
  text-decoration: underline !important;
  text-underline-offset: 2px !important;
}

.reroute:hover {
  color: #4233b0d9 !important;
  cursor: pointer !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
  text-decoration-thickness: 2px !important;
}
.back-login {
  font-weight: 500 !important;
  cursor: pointer;
}

.back-login:hover {
  font-weight: 600 !important;
  cursor: pointer;
}

.no-record-image {
  width: 600px;
  height: 400px;
}

@media (max-width: 768px) {
  .no-record-image {
    width: 400px;
    height: 200px;
  }
}

@media (max-width: 500px) {
  .no-record-image {
    width: 200px;
    height: 100px;
  }
}
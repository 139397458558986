.form-input input {
  display: block;
  padding: 5px;
  height: 40px;
  border-radius: 8px !important;
  /* border: 1px solid #aca2f8; */
  border: 1px solid #cccccc;
  outline: none !important;
  font-size: 14px;
  width: 100%;
  background: #fff;
  color: #000;
}

.login-form-input input {
  display: block;
  padding: 5px;
  padding-left: 15px;
  height: 40px;
  border-radius: 8px !important;
  /* border: 1px solid #aca2f8; */
  border: 1px solid #cccccc;
  outline: none !important;
  font-size: 14px;
  width: 100%;
  background: #fff;
  color: #000;
  font-weight: 500 !important;
}

.form-input input::placeholder {
  color: #aaadb2;
}
.login-form-input input::placeholder {
  color: #b6c1ca;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.error-input input {
  border: 1px solid #e10000;
}

.disabled-field input {
  background-color: #f7f7f7 !important;
  cursor: not-allowed !important;
}

.form-input input:focus {
  border: 2px solid #08ab93;
  box-shadow: none !important;
}

.login-form-input input:focus {
  border: 2px solid #08ab93;
  /* background-color: #f0f0f0 !important; */
  box-shadow: none !important;
}

.form-input select {
  display: block;
  padding: 5px;
  height: 40px;
  border-radius: 8px !important;
  /* border: 1px solid #aca2f8; */
  border: 1px solid #cccccc;
  outline: none !important;
  font-size: 14px;
  width: 100%;
  background-color: #fff !important;
}

.disabled-field select {
  display: block;
  padding: 5px;
  height: 40px;
  border-radius: 8px !important;
  /* border: 1px solid #aca2f8; */
  border: 1px solid #cccccc;
  outline: none !important;
  font-size: 14px;
  width: 100%;
  background-color: #fff !important;
}

.error-input select {
  border: 1px solid #e10000;
}

.disabled-field select {
  background: #f7f7f7
    url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat !important;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-left: 5px !important;
  padding-right: 2rem !important;
  -webkit-appearance: none;
  cursor: not-allowed !important;
}

.disabled-field textarea {
  background-color: #f7f7f7 !important;
  cursor: not-allowed !important;
}

.error-input textarea {
  border: 1px solid #e10000;
}

.form-input select:focus {
  border: 2px solid #08ab93 !important;
  box-shadow: none !important;
}

.form-input select {
  background: white
    url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat !important;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-left: 5px !important;
  padding-right: 2rem !important;
  -webkit-appearance: none;
}

.custom-dropselect:focus {
  border: 1px solid #08ab93 !important;
  box-shadow: none !important;
}

.form-input textarea {
  display: block;
  padding: 5px;
  height: 120px;
  border-radius: 8px !important;
  border: 1px solid #cccccc;
  background-color: #fff;
  outline: none !important;
  font-size: 14px;
  width: 100%;
}

.form-input textarea:focus {
  border: 2px solid #08ab93;
}

.form-input label {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.custom-select-label {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  top: 7px;
}

.icon-relative {
  position: relative;
}

.icon-absolute {
}

.text-class textarea:focus {
  border: 2px solid #08ab93;
}

.required-input {
  font-weight: 400 !important;
  padding-left: 2px;
}

.optional-text {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 5px;
}

.remove-members {
  font-size: 12px;
  font-weight: 600;
}

/* Date Picker CSS */

.date_input {
  margin-top: 2px;
}
.date_input {
  margin-top: 2px;
}

.search_input {
  border: 1px solid #c5d0d8;
  height: 38px;
  border-radius: 8px;
  padding: 19px 12px !important;
}

.search_input:focus {
  outline: none !important;
  border-color: #0046be;
  box-shadow: none;
}

/* DATE PICKER CSS */

.react-datepicker-popper {
  z-index: 10 !important;
}

.react-datepicker-wrapper {
  display: block !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker__day-names {
  position: relative;
  top: -7px !important;
  color: white !important;
}

.react-datepicker__month {
  line-height: 3em !important;
}

.react-datepicker__day-name {
  width: 2.5em !important;
  line-height: 2.5em !important;
  color: #fff !important;
  font-weight: bold !important;
}

.react-datepicker__header__dropdown {
  display: none !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker-popper .react-datepicker__navigation {
  padding-top: 12px;
  color: #000;
}

.react-datepicker {
  /* width: 300px !important; */
  /* height:30px !important; */
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
  border: none !important;
}

.react-datepicker__header {
  /* border-bottom: solid 5px var(--light) !important; */

  /* background: rgb(19, 76, 95) !important; */
  background: #08ab93 !important;
}

.react-datepicker__month-container {
  float: none !important;
  margin: 0 auto !important;
}

.react-datepicker__current-month {
  color: #fff !important;
  font-size: 16px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  font-weight: 600 !important;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 7px;
  border: solid 2px var(--brand) !important;
  background-color: white !important;
  color: var(--dark) !important;
  width: 30px;
  height: 30px;
}

.react-datepicker__day {
  margin: 7px !important;
}

.react-datepicker__day.react-datepicker__day--selected {
  border: none;
  border-radius: 50%;
  background-color: #08ab93 !important;
  color: white;
  font-weight: bold;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border: none;
  border-radius: 7px !important;
  background-color: var(--brand) !important;
  color: var(--dark) !important;
}

.react-datepicker__year-select {
  background-color: #fff !important;
  position: relative !important;
  top: -17px !important;
  left: 60px !important;
  font-size: 14px !important;
  display: none;
}

.react-datepicker__navigation-icon {
  top: 8px !important;
}

.react-datepicker__navigation {
  height: 40px !important;
}

.disabled-date-field input {
  background-color: #f7f7f7 !important;
}

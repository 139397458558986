.login-button {
  width: 100%;
  background-color: #08ab93;
  border: 1px solid #08ab93;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 8px 0px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 10px;
}

.form-group {
  color: #232d83;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.form-group label {
  padding-bottom: 10px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 5;
  background-clip: padding-box;
  border-bottom: 1px solid;
  color: black;
  background-color: #fff;
  border-color: #fff;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  border-color: #fd9f2d;
  box-shadow: 0 0 0 0.2rem rgba(23, 116, 239, 0.55);
  background-color: #0f337c;
  color: black;
}

.btn-primary:focus {
  border-color: #0f337c;
  box-shadow: 0 0 0 0.2rem rgba(19, 60, 241, 0.55);
  background-color: white;
  color: #0f337c;
}

.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: black;
  background-color: white;
  border-color: #0f337c;
}

.password-input {
  position: relative;
}

.password-icon {
  position: absolute;
  bottom: 0.5rem;
  color: black;
  /* left: 16.2rem; */
  right: 8px;
}

.login-main {
  /* background-image: url("../../assets/images/Background.png"); */
  width: 100%;
  height: 99vh;
  /* background-color: #bl; Used if the image is unavailable */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Do not repeat the image */
  background-size: cover;
  overflow: hidden;
  /* Resize the background image to cover the entire container */
}

.login-Alert {
  position: absolute;
  top: 30px;
  right: 30px;
}

.jumbotron.login {
  background-color: #232d83;
  border-radius: 30px;
}

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.background {
  background-color: #f0f0f0;
  color: white;
  padding: 2rem;
  height: 600px;
  max-height: 600px;
}

.background-white {
  background-color: white;
  position: relative;
  border-radius: 3rem 0 0 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.padding-input {
  padding: 0.375rem 0.75rem;
  height: calc(1.5em + 0.75rem + 2px);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: #fff;
}
.content {
  border-radius: 3rem 3rem 3rem 3rem;
}

.login-form {
  background-color: #fff;
  border-radius: 2rem;
}

.password-eye {
  top: 13px !important;
}

.login-summary {
  font-size: 14px;
  font-weight: 600;
  color: #616161;
}

.login-sub-heading {
  font-size: 14px;
  font-weight: 700;
  color: #616161;
}

.icon-badge {
  background-color: #08ab93;
  border-radius: 25px;
  width: 40px;
  padding: 1px;
  font-weight: bolder;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .background {
    padding: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .login-main {
    height: auto;
  }
}

.student,
.faculty {
  background-color: #f0f0f0;
  width: 100%;
  color: #05262166;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
}
.faculty {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.student {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
/* .student:hover,
.faculty:hover {
  background-color: transparent;
  color: #08ab93;
} */
.student_active,
.faculty_active {
  border-radius: 8px;
  background-color: #08ab93;
  color: white;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 12px;
  border: 1px solid #08ab93;
  /* transition-duration: 2s; */
}
.register .department-select::placeholder {
  background-color: #aaadb2 !important;
  color: #000;
}
.register .department-select {
  display: block;
  padding: 5px;
  height: 40px;
  border-radius: 8px !important;
  /* border: 1px solid #aca2f8; */
  border: 1px solid #cccccc;
  outline: none !important;
  font-size: 14px;
  width: 100%;
  background: #fff;
  color: #000;
}
